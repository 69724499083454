import { useToggle } from "@react-hookz/web";
import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { useArticleDateUtils } from "scmp-app/components/article/article-date/hooks";
import type { defaultArticleDateContent$key } from "scmp-app/queries/__generated__/defaultArticleDateContent.graphql";

import { PublishedDateContainer, StyledDiv, UpdatedDateContainer } from "./styles";

export type Props = {
  className?: string;
  isEnableToggle: boolean;
  reference: defaultArticleDateContent$key;
};

export const DefaultArticleDate: FunctionComponent<Props> = ({
  className,
  isEnableToggle = true,
  reference: reference_,
  ...attribs
}) => {
  const content = useFragment(
    graphql`
      fragment defaultArticleDateContent on Content {
        ...hooksArticleDateUtilsContent
      }
    `,
    reference_,
  );
  const [isToggle, setToggle] = useToggle(false);

  const getToggleStatus = () => {
    if (!isEnableToggle) {
      return true;
    }
    return isToggle;
  };

  const { isoPublishedDate, isoUpdatedDate, lastUpdatedDate, publishedDate } =
    useArticleDateUtils(content);

  if (!content) return null;

  return (
    <StyledDiv className={className} onClick={setToggle} {...attribs}>
      <PublishedDateContainer $isToggle={getToggleStatus()}>
        <time dateTime={isoPublishedDate}>Published: {publishedDate}</time>
      </PublishedDateContainer>
      {getToggleStatus() && isoUpdatedDate && (
        <UpdatedDateContainer>
          <time dateTime={isoUpdatedDate}>Updated: {lastUpdatedDate}</time>
        </UpdatedDateContainer>
      )}
    </StyledDiv>
  );
};

DefaultArticleDate.displayName = "DefaultArticleDate";
