import { notEmpty } from "@product/scmp-sdk";
import { useSetAtom } from "jotai";
import { useRouter } from "next/router";
import type { ParsedUrlQueryInput } from "querystring";
import { useCallback } from "react";

import { plusPopupAtom } from "scmp-app/components/plus/atoms";
import { removeQueryString } from "scmp-app/components/plus/helpers";

import {
  encodePlusDetailedListingQuery,
  keysOfEncodedPlusDetailedListingQuery,
} from "./navigation";
import type { PlusDetailedListing } from "./types";

export const usePlusDetailedListingPopup = () => {
  const router = useRouter();
  const setPlusPopupState = useSetAtom(plusPopupAtom);

  const cleanQueryString = (input: ParsedUrlQueryInput) =>
    Object.fromEntries(Object.entries(input).filter(([_, value]) => notEmpty(value)));

  const handleOpen = useCallback(
    (plusDetailedListing: PlusDetailedListing) => {
      const queryString = encodePlusDetailedListingQuery(plusDetailedListing);

      const url = new URL(window.location.href);
      Object.entries(queryString).forEach(entry => url.searchParams.set(...entry));

      setPlusPopupState(current => ({
        ...current,
        isShowDetailListingPopup: true,
      }));

      void router.push(
        {
          pathname: router.pathname,
          query: cleanQueryString({
            ...router.query,
            ...queryString,
          }),
        },
        url.toString(),
        {
          scroll: false,
          shallow: true,
        },
      );
    },
    [router, setPlusPopupState],
  );

  const handleClose = useCallback(() => {
    setPlusPopupState(current => ({
      ...current,
      isShowDetailListingPopup: false,
    }));

    const url = new URL(window.location.href);

    /** query parameter `pgtype` should not be cleaned after the drawer close */
    const keysGeneratedByOpeningPopup = keysOfEncodedPlusDetailedListingQuery.filter(
      key => key !== "pgtype",
    );
    keysGeneratedByOpeningPopup.forEach(key => url.searchParams.delete(key));

    void router.push(
      {
        pathname: router.pathname,
        query: cleanQueryString(removeQueryString(router.query, keysGeneratedByOpeningPopup)),
      },
      url.toString(),
      {
        scroll: false,
        shallow: true,
      },
    );
  }, [router, setPlusPopupState]);

  return {
    handleClose,
    handleOpen,
  };
};
