import styled from "@emotion/styled";
import { fontRoboto } from "@product/scmp-sdk";

export const AuthorNamesContainer = styled.div`
  margin: 0;

  font-weight: 700;
  font-size: 14px;
  font-family: ${fontRoboto};
  line-height: 21px;
`;

export const CommaSeparator = styled.span`
  padding-inline-end: 4px;
`;

export const AndSeparator = styled.span`
  padding-block: 0;
  padding-inline: 4px;

  font-weight: 400;
`;

export const AuthorLocation = styled.span`
  padding-inline-start: 4px;

  color: #4585ff;
`;
