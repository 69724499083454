/**
 * @generated SignedSource<<6597d78a4774b550548cc81dafecfcc8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type authorNamesArticle$data = {
  readonly authorLocations: ReadonlyArray<string | null | undefined> | null | undefined;
  readonly authors: ReadonlyArray<{
    readonly entityId: string;
    readonly location: string | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"authorNameAuthor">;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "authorNamesArticle";
};
export type authorNamesArticle$key = {
  readonly " $data"?: authorNamesArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"authorNamesArticle">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "authorNamesArticle",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "authorLocations",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Author",
      "kind": "LinkedField",
      "name": "authors",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "entityId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "location",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "authorNameAuthor"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Article",
  "abstractKey": null
};

(node as any).hash = "5594af4c4a1b74bd9308607946c4aa51";

export default node;
