import { notEmpty } from "@product/scmp-sdk";
import take from "lodash/take";
import type { FunctionComponent, ReactNode } from "react";
import { Fragment, useCallback } from "react";
import { graphql, useFragment } from "react-relay";

import { AuthorName } from "scmp-app/components/author/author-name";
import { usePlusDetailedListingPopup } from "scmp-app/components/plus/detailed-listing-popup/hooks";
import type { authorNamesArticle$key } from "scmp-app/queries/__generated__/authorNamesArticle.graphql";

import { AuthorLocation as Location } from "./enums";
import { AndSeparator, AuthorLocation, AuthorNamesContainer, CommaSeparator } from "./styles";

export type Props = {
  children?: ReactNode;
  firstNthAuthor?: number;
  reference: authorNamesArticle$key;
  variant?: "plus";
  withLocation?: boolean;
};

export const AuthorNames: FunctionComponent<Props> = ({
  children,
  firstNthAuthor,
  reference: reference_,
  variant,
  withLocation = true,
  ...attribs
}) => {
  const { handleOpen } = usePlusDetailedListingPopup();
  const { authorLocations, authors: authorsData } = useFragment(
    graphql`
      fragment authorNamesArticle on Article {
        authorLocations
        authors {
          entityId
          location
          ...authorNameAuthor
        }
      }
    `,
    reference_,
  );

  const authors = firstNthAuthor ? take(authorsData, firstNthAuthor) : authorsData;

  const renderLocations = useCallback(
    (index: number) => {
      if (!authors) return null;
      const authorsLocation = authors.map((author, index) => {
        const authorLocationInArticle = (authorLocations as OrUndefined<string>[])?.[index];

        if (!notEmpty(authorLocationInArticle)) return author?.location;
        else if ((authorLocationInArticle?.toLowerCase?.() as Location) === Location.NotAvailable)
          return;
        return authorLocationInArticle;
      });
      const isAllAuthorsInHongKongOrEmpty = authorsLocation.every(
        // Check all author locations are Hong Kong or empty
        location => location?.toLowerCase() === Location.HongKong || !notEmpty(location),
      );

      const location = authorsLocation[index];
      if (authors.length > 3 || isAllAuthorsInHongKongOrEmpty || !location) return null;
      return <AuthorLocation>in {location}</AuthorLocation>;
    },
    [authorLocations, authors],
  );

  const handleAuthorOnClick = useCallback(
    (entityUuid: string) => {
      if (variant === "plus") {
        handleOpen({
          entityUuid,
          type: "author",
        });
      }
    },
    [handleOpen, variant],
  );

  if (!notEmpty(authors)) return null;

  const renderCommaOrAnd = (index: number) => {
    const lastIndex = authors.length - 1;
    if (authors.length <= 1 || index === lastIndex) return null;
    // If current index is previous index before lastIndex in the array (No need to render and or , at the last item)
    return index === lastIndex - 1 ? (
      <AndSeparator>and</AndSeparator>
    ) : (
      <CommaSeparator>,</CommaSeparator>
    );
  };

  return (
    <AuthorNamesContainer {...attribs}>
      {authors?.map((author, index) => {
        if (!author) return null;

        return (
          <Fragment key={author.entityId}>
            <AuthorName
              onClick={handleAuthorOnClick}
              reference={author}
              withoutLink={variant === "plus"}
            />
            {withLocation && renderLocations(index)}
            {renderCommaOrAnd(index)}
          </Fragment>
        );
      })}
      {children}
    </AuthorNamesContainer>
  );
};

AuthorNames.displayName = "AuthorNames";
