import styled from "@emotion/styled";
import { fontBarlowSemiCondensed, fontRobotoCondensed, theme } from "@product/scmp-sdk";

import { BaseLink } from "shared/components/common/base-link";

import { EntityLink } from "scmp-app/components/entity-link";

export const SectionContainer = styled.div``;

export const SectionText = styled.span`
  margin: 0;

  color: #aaaaaa;
  font-size: 18px;
  font-family: ${fontRobotoCondensed};
  line-height: 21px;

  &:last-child {
    color: #666666;
  }

  ${theme.breakpoints.up("tablet")} {
    font-size: 24px;
    line-height: 28px;
  }
`;

export const SectionLink = styled(EntityLink)`
  margin: 0;

  color: #aaaaaa;
  font-size: 18px;
  font-family: ${fontRobotoCondensed};
  line-height: 21px;

  &:last-child {
    color: #666666;
  }

  ${theme.breakpoints.up("tablet")} {
    font-size: 24px;
    line-height: 28px;
  }
`;

export const InfographicLink = styled(BaseLink)`
  margin: 0;

  color: #666666;
  font-size: 18px;
  font-family: ${fontRobotoCondensed};
  line-height: 21px;

  ${theme.breakpoints.up("tablet")} {
    font-size: 24px;
    line-height: 28px;
  }
`;

export const MagazinesStyleSectionLink = styled(EntityLink)`
  margin: 0;

  color: #222222;
  font-weight: 500;
  font-size: 14px;
  font-family: ${fontBarlowSemiCondensed};
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.4px;
  text-align: center;
  text-transform: uppercase;

  ${theme.breakpoints.up("tablet")} {
    font-size: 16px;
    letter-spacing: 1.6px;
  }

  ${theme.breakpoints.up("desktop")} {
    font-size: 20px;
    letter-spacing: 2px;
  }
`;

export const SectionTagsSpacer = styled.span`
  margin-block: 0;
  margin-inline: 0.25rem;

  ::after {
    content: "/";
  }
`;
