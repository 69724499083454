import first from "lodash/first";
import last from "lodash/last";
import type { FunctionComponent } from "react";
import { useMemo } from "react";
import { graphql, useFragment } from "react-relay";

import { checkIfNewsSectionInFirstIndex } from "scmp-app/lib/section";
import type { articleSectionsContent$key } from "scmp-app/queries/__generated__/articleSectionsContent.graphql";

import {
  InfographicLink,
  MagazinesStyleSectionLink,
  SectionContainer,
  SectionLink,
  SectionTagsSpacer,
  SectionText,
} from "./styles";

export type Props = {
  className?: string;
  isDisableLink?: boolean;
  reference: articleSectionsContent$key;
  variant?: "generic" | "infographic" | "magazines-style";
};

export const ArticleSections: FunctionComponent<Props> = ({
  className,
  isDisableLink = false,
  reference: reference_,
  variant = "generic",
}) => {
  const { sections } = useFragment(
    graphql`
      fragment articleSectionsContent on Content {
        sections {
          value {
            ...helpersCheckIfNewsSectionInFirstIndexSection
            ...entityLink
            name
          }
        }
      }
    `,
    reference_,
  );

  const firstSection = useMemo(() => first(sections)?.value ?? [], [sections]);

  // Confirmed with PO for display two sections
  const sectionsForDisplay = useMemo(
    () =>
      firstSection
        .slice(-2)
        .filter((section, index) => !checkIfNewsSectionInFirstIndex(section, index)),
    [firstSection],
  );

  const render = () => {
    switch (variant) {
      case "generic":
        return sectionsForDisplay.map((section, index) => {
          if (isDisableLink) {
            return (
              <SectionText key={index}>
                <span>{section?.name}</span>
                {index < sectionsForDisplay.length - 1 ? <SectionTagsSpacer /> : null}
              </SectionText>
            );
          }
          if (section) {
            return (
              <SectionLink key={index} reference={section}>
                <span>{section?.name}</span>
                {index < sectionsForDisplay.length - 1 ? <SectionTagsSpacer /> : null}
              </SectionLink>
            );
          }
          return null;
        });
      case "infographic":
        const infographicSection = last(sectionsForDisplay);
        return infographicSection ? (
          <>
            <SectionLink reference={infographicSection}>
              <span>{infographicSection?.name}</span>
              <SectionTagsSpacer />
            </SectionLink>
            <InfographicLink pathname="/infographic">Infographics</InfographicLink>
          </>
        ) : null;
      case "magazines-style":
        const section = last(sectionsForDisplay);
        return section ? (
          <MagazinesStyleSectionLink reference={section}>
            <span>{section?.name}</span>
          </MagazinesStyleSectionLink>
        ) : null;
    }
  };

  if (sectionsForDisplay.length === 0) return null;

  return <SectionContainer className={className}>{render()}</SectionContainer>;
};

ArticleSections.displayName = "ArticleSections";
