import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { fontRoboto, theme } from "@product/scmp-sdk";

export const StyledDiv = styled.div`
  position: relative;

  color: #aaaaaa;
  font-size: 14px;
  font-family: ${fontRoboto};
  line-height: 21px;

  cursor: pointer;

  ${theme.breakpoints.up("tablet")} {
    min-block-size: 32px;

    line-height: 16px;
  }
`;

type PublishedDateProps = {
  $isToggle: boolean;
};
export const PublishedDateContainer = styled.div<PublishedDateProps>`
  &:after {
    position: absolute;
    content: "";

    margin-block-start: 7px;
    margin-inline-start: 7px;

    border-inline-end: 4px solid transparent;
    border-block-end: 4px solid #aaaaaa;
    border-inline-start: 4px solid transparent;

    transform: rotate(180deg);
    ${props => {
      if (!props.$isToggle) return;
      return css`
        margin-block-start: 8px;

        transform: rotate(0deg);
      `;
    }}
  }
`;

export const UpdatedDateContainer = styled.div``;
